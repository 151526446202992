export function parseDate(input, format) {
    format = format || 'yyyy-mm-dd';
    let parts = input.match(/(\d+)/g),
        i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function(part) { fmt[part] = i++; });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']]);
}

export function parseDateTime(input, format) {
    format = format || 'yyyy-mm-dd HH:MM'; // default format
    let parts = input.match(/(\d+)/g),
        i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm|HH|MM)/g, function(part) { fmt[part] = i++; });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']]-1, parts[fmt['dd']], parts[fmt['HH']], parts[fmt['MM']]);
}

export function parseTime(input, format) {
    format = format || 'HH:MM'; // default format
    let parts = input.match(/(\d+)/g),
        i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(HH|MM)/g, function(part) { fmt[part] = i++; });

    return new Date(2000, 11, 31, parts[fmt['HH']], parts[fmt['MM']]);
}
